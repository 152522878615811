@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f5dfbb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.blue-float {
  background-color: #114b5f;
  position: absolute;
  width: 50vw;
  height: 100vh;
  right: 0;
  top: 0;
}

.App-logo {
  z-index: 20;
  position: relative;
  left: -5px;
}

.imagePar {
  display: flex;
  align-items: center;
  justify-content: center
}

.imagePar img {
    max-width: 100%
}

.image {
  flex-basis: 40%;
}

.imagePar p {
  padding: 4rem;
}